<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Dados do Participante</div>
      </v-col>
    </v-row>
    <form>
      <v-row no-gutters class="form-row">
        <v-col :cols="style.colLeft" :align-self="style.alignSelf" :class="style.colLeftClasses">
          CPF do Participante
        </v-col>
        <v-col :cols="style.colRight" :align-self="style.alignSelf" :class="style.colRightClasses">
          <input
            id="cpf"
            maxlength="14"
            name="cpf"
            size="23"
            type="text"
            v-mask="masks.cpf"
            v-model="participante.cpf"
          />
        </v-col>
      </v-row>
    </form>
    <v-row no-gutters>
      <v-col class="text-center my-3">
        <a @click="salvar" class="btn-azul" id="btn-salvar">Salvar</a>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="text-center">
        <div><b>Participantes</b></div>
      </v-col>
    </v-row>

    <div v-for="item in this.participantes" :key="item.cpf">
      <v-row no-gutters class="mt-4">
        <v-col cols="3">
          CPF do Participante:
        </v-col>
        <v-col cols="8">{{ item.cpf }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          Nome do Participante:
        </v-col>
        <v-col cols="8">{{ item.nome }}</v-col>
      </v-row>
      <v-row class="ma-1" no-gutters>
        <v-col class="text-right">
          <a @click="excluir(item)" class="btn-azul" id="btn-excluir">Excluir</a>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
// noinspection ES6CheckImportt
import _ from "lodash";
import { mapAvaliacaoRiscoFields } from "../../../store/modules/caixaqui-avaliacao-risco";
import { mapActions } from "vuex";
import { OPTIONS } from "../../../config";

const participanteNew = {
  cpf: null,
  nome: null
};

export default {
  name: "CaixaquiAvaliacaoRiscosDadosParticipante",

  data: () => ({
    masks: OPTIONS.masks,
    style: {
      colLeft: 6,
      colRight: 6,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    participante: _.cloneDeep(participanteNew)
  }),
  computed: {
    ...mapAvaliacaoRiscoFields(["participantes", "cpf"])
  },
  methods: {
    ...mapActions("caixaquiAvaliacaoRisco", ["addParticipante", "removeParticipante"]),
    ...mapActions("controller", ["findCliente"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    isMaiorRenda(participante, tomador) {
      this.$log.debug("Participante", participante);
      this.$log.debug("Tomador", tomador);

      //TODO - AQUI TEMOS VERIFICAR SE A RENDA DO TOMADOR EH MAIOR, CASO CONTRARIO DEVE LANCAR A MENSAGEM
      return false;
    },
    async salvar() {
      if (this.participante.cpf) {
        const participante = await this.findCliente(this.participante.cpf);
        this.$log.debug("Participante encontrado:", participante);
        if (participante && participante.nomeCompleto) {
          this.participante.nome = participante.nomeCompleto.toUpperCase();

          const tomador = await this.findCliente(this.cpf);

          if (tomador.cpf === this.participante.cpf) {
            // TODO validar mensagem
            alert("Participante deve ser diferente do tomador.");
            return;
          }

          const maiorRenda = this.isMaiorRenda(participante, tomador);
          if (maiorRenda) {
            alert("Tomador deve ser o participante com maior renda.");
            return;
          }

          this.addParticipante(this.participante);
          this.participante = _.cloneDeep(participanteNew);
        } else {
          alert("Participante deve ter sido previamente cadastrado.");
        }
      }
    },
    excluir(participante) {
      this.removeParticipante(participante);
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#btn-excluir,
#btn-salvar {
  padding: 3px 12px 3px 12px;
}

#cpf {
  width: 175px;
  margin-right: 4px;
}
</style>
