var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados do Participante")])
          ])
        ],
        1
      ),
      _c(
        "form",
        [
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    cols: _vm.style.colLeft,
                    "align-self": _vm.style.alignSelf
                  }
                },
                [_vm._v("\n        CPF do Participante\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    cols: _vm.style.colRight,
                    "align-self": _vm.style.alignSelf
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.masks.cpf,
                        expression: "masks.cpf"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.participante.cpf,
                        expression: "participante.cpf"
                      }
                    ],
                    attrs: {
                      id: "cpf",
                      maxlength: "14",
                      name: "cpf",
                      size: "23",
                      type: "text"
                    },
                    domProps: { value: _vm.participante.cpf },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.participante, "cpf", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center my-3" }, [
            _c(
              "a",
              {
                staticClass: "btn-azul",
                attrs: { id: "btn-salvar" },
                on: { click: _vm.salvar }
              },
              [_vm._v("Salvar")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("div", [_c("b", [_vm._v("Participantes")])])
          ])
        ],
        1
      ),
      _vm._l(this.participantes, function(item) {
        return _c(
          "div",
          { key: item.cpf },
          [
            _c(
              "v-row",
              { staticClass: "mt-4", attrs: { "no-gutters": "" } },
              [
                _c("v-col", { attrs: { cols: "3" } }, [
                  _vm._v("\n        CPF do Participante:\n      ")
                ]),
                _c("v-col", { attrs: { cols: "8" } }, [
                  _vm._v(_vm._s(item.cpf))
                ])
              ],
              1
            ),
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { attrs: { cols: "3" } }, [
                  _vm._v("\n        Nome do Participante:\n      ")
                ]),
                _c("v-col", { attrs: { cols: "8" } }, [
                  _vm._v(_vm._s(item.nome))
                ])
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "ma-1", attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "text-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-azul",
                      attrs: { id: "btn-excluir" },
                      on: {
                        click: function($event) {
                          return _vm.excluir(item)
                        }
                      }
                    },
                    [_vm._v("Excluir")]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }